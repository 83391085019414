import $ from 'jquery';
import StickySidebar from 'sticky-sidebar';

import {cartcouponclick} from './partials/cart-change';

import {checkounextbutton} from './partials/checkout-next-button';
import {stickyCat} from './partials/stickyCat';

import '../scss/pages/_checkout.scss';

 
/* smartform.afterInit = function () {
  smartform.getInstance('smartform-instance-billing').addressControl.setCountry('SK')  
  smartform.getInstance('smartform-instance-shipping').addressControl.setCountry('SK')        
}  */
$(document).ready(function() {
  
  stickysidebar()
  cartcouponclick();
  checkounextbutton(stickyCat);
  $('.checkout-company-selection-wrap .company-selection-item').on('click',function(e){
    e.preventDefault();
    if(!$(this).hasClass('active')) {
      $('.checkout-company-selection-wrap .company-selection-item').removeClass('active');
      $(this).addClass('active');
      
    }
    if($(this).hasClass('company')) {
      $('.form-group.company-fields').show()
      $('.form-control[name="wi_as_company"] ').val(1);
      $('.form-group.company-fields .form-control[name="billing_company_wi_id"] ').attr('required',true);
      $('.form-group.company-fields .form-control[name="billing_company_wi_tax"] ').attr('required',true);
    }else {
      $('.form-group.company-fields').hide()
      $('.form-group.company-fields .form-control ').val('');
      $('.form-control[name="wi_as_company"] ').val(0);
      $('.form-group.company-fields .form-control[name="billing_company_wi_id"] ').attr('required',false);
      $('.form-group.company-fields .form-control[name="billing_company_wi_tax"] ').attr('required',false);
    }
    $.each(stickyCat,function(index, value){
      stickyCat[index].updateSticky();
    })
  })

  $('input[name="note_field_item"]').on('change',function(e){
    e.preventDefault();
    $('.woocommerce-additional-fields__field-wrapper_note').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200, function(){
      $.each(stickyCat,function(index, value){
        stickyCat[index].updateSticky();
      })  
    } );
    
  })


  $('.checkout').off();

  $('input[name="ship_to_different_address"]').on('change',function(e){
    e.preventDefault();
    
    if($(this).is(':checked')) {
      $('.shipping_address').animate({
        height: "toggle",
        opacity: "toggle"
      }, 200, function(){
        $.each(stickyCat,function(index, value){
          stickyCat[index].updateSticky();
        })  
      }  );
      $('.shipping_address .form-control').each(function(){
        $(this).attr('required',true);
      })
    }else {
      $('.shipping_address').animate({
        height: "toggle",
        opacity: "toggle"
      }, 200, function(){
        $.each(stickyCat,function(index, value){
          stickyCat[index].updateSticky();
        })  
      }  );
      $('.shipping_address .form-control').each(function(){
        $(this).attr('required',false);
      })
    }
    
  })

  
  checkouprevbutton();


  $('.form-select[name="billing_country"], .form-select[name="shipping_country"], input[name="ship_to_different_address"]').on('change',function(e) {
    e.preventDefault();
    let instance = $(this).attr('data-instance');
    let thisval = $(this).val();
    //console.log(instance);
    
    changeshipping()
  })

  $('form.lemon-checkout-form').on('submit',function(e){
    //console.log('xxxx')
    e.preventDefault();
    
    $(this).addClass('form-validate');
    
    let isValid = true;
    let adress = false;
    let thisform = $(this);
    $('.error-results',thisform).html('')
    if (!isValid) {
      
      $('html, body').animate({
        scrollTop: $(".form-control:invalid",$(thisform)).offset().top - 150
      }, 300);  
      
      
    }
    
    //console.log(isValid);
    //console.log(adress);
    if (isValid) {
      //$('.loading-loader').show();
      var $form = $( this );

      if ( $form.is( '.processing' ) ) {
        return false;
      }

    // Trigger a handler to let gateways manipulate the checkout if needed
			// eslint-disable-next-line max-len
			if ( $form.triggerHandler( 'checkout_place_order' ) !== false && $form.triggerHandler( 'checkout_place_order_' +get_payment_method() ) !== false ) {

				$form.addClass( 'processing' );


				// Attach event to block reloading the page when the form has been submitted
				//wc_checkout_form.attachUnloadEventsOnSubmit();

				// ajaxSetup is global, but we use it to ensure JSON is valid once returned.
				$.ajaxSetup( {
					dataFilter: function( raw_response, dataType ) {
						// We only want to work with JSON
						if ( 'json' !== dataType ) {
							return raw_response;
						}


						return raw_response;
					}
				} );
        var data = $form.serialize();

        //console.log(data);
        $('.loading-loader').show();
				$.ajax({
					type:		'POST',
					url:		wc_checkout_params.checkout_url,
					data:		data,
					dataType:   'json',
					success:	function( result ) {
						// Detach the unload handler that prevents a reload / redirect
						//wc_checkout_form.detachUnloadEventsOnSubmit();
            //console.log(result);
						try {
							if ( 'success' === result.result && $form.triggerHandler( 'checkout_place_order_success' ) !== false ) {
								if ( -1 === result.redirect.indexOf( 'https://' ) || -1 === result.redirect.indexOf( 'http://' ) ) {
									window.location = result.redirect;
								} else {
									window.location = decodeURI( result.redirect );
								}
							} else if ( 'failure' === result.result ) {
                $('.loading-loader').hide();
                $('.error-results').html(result.messages)
                $form.removeClass( 'processing' );
								throw 'Result failure';
							} else {
								throw 'Invalid response';
							}
						} catch( err ) {
							// Reload page
							if ( true === result.reload ) {
								window.location.reload();
								return;
							}

							// Trigger update in case we need a fresh nonce
							if ( true === result.refresh ) {
								$( document.body ).trigger( 'update_checkout' );
							}

							
						}
            //$('.loading-loader').hide();
					},
					error:	function( jqXHR, textStatus, errorThrown ) {
            $('.loading-loader').hide();
						// Detach the unload handler that prevents a reload / redirect
						//wc_checkout_form.detachUnloadEventsOnSubmit();

						//wc_checkout_form.submit_error( '<div class="woocommerce-error">' + errorThrown + '</div>' );
					}
				});
			}
    
    }

  })

  paymentonchange();
  schippingonchange();
  companyonchange();

  trustpay($('.payment-wrap input[name^="payment_method"]:checked'))


  $('#order_review .payment-wrap input[name^="payment_method"]').on('change',function(e){
    trustpay($(this));
  });

});


function stickysidebar() {
  if ( $('.js-sticky-cartblock').length) {
    
    if ($(window).width() > 992) {
      $('.js-sticky-cartblock').each(function(index){
        stickyCat[index] = new StickySidebar(this, {
          //listen: true,
          topSpacing: 110,
          bottomSpacing: 110,
          containerSelector: '.row',
          resizeSensor: true,
        })
      })
      
    }
    $(window).on('resize', function(){
      
      if ($(window).width() > 992) {
        if(stickyCat == null || stickyCat.length == 0){
          if ( $('.js-sticky-cartblock').length) {
            $('.js-sticky-cartblock').each(function(index){
              stickyCat[index] = new StickySidebar(this, {
                //listen: true,
                topSpacing: 110,
                bottomSpacing: 110,
                containerSelector: '.row',
                resizeSensor: true,
              })
            })
          }
        }
      }else {
        if (stickyCat.length) {
          $.each(stickyCat,function(index, value){
            stickyCat[index].destroy();
          })
          
          stickyCat.length = 0;
        }
        
      }
    })  
  }

}

function get_payment_method() {
  return $('form.lemon-checkout-form').find( 'input[name="payment_method"]:checked' ).val();
}

function schippingonchange() {
  $('.shiping-wrap input[name^="shipping_method"]').on('change',function(e){
    e.preventDefault();
    //$('.checkout').off();
    changeshipping()

  })
}

function paymentonchange() {
 
  $('.lemon-checkout-form .payment-wrap input[name^="payment_method"]').on('change',function(e){

    trustpay($(this));
    //$('.payment-wrap .form-check .notice').hide();
    //$(this).closest('.form-check').find('.notice').show();
    
    changeshipping()
  });
}

function companyonchange() {
 
  $('.lemon-checkout-form .company-preselect-wrap input[name^="company"]').on('change',function(e){

    //trustpay($(this));
    //$('.payment-wrap .form-check .notice').hide();
    //$(this).closest('.form-check').find('.notice').show();
    
    changeshipping()
  });
}

export function changeshipping() {
  $('.loading-loader').show();
  var email			 = $( '#billing_email' ).val(),
    first_name			 = $( '#billing_first_name' ).val(),
    last_name			 = $( '#billing_last_name' ).val(),
    phone			 = $( '#billing_phone' ).val(),
    country			 = $( '#billing_country' ).val(),
    state			 = $( '#billing_state' ).val(),
    postcode		 = $( ':input#billing_postcode' ).val(),
    city			 = $( '#billing_city' ).val(),
    address			 = $( ':input#billing_address_1' ).val(),
    address_2		 = $( ':input#billing_address_2' ).val(),
    company			 = $( '#billing_company' ).val(),
    company_wi_id			 = $( '#billing_company_wi_id' ).val(),
    company_wi_vat			 = $( '#billing_company_wi_vat' ).val(),
    company_wi_tax			 = $( '#billing_company_wi_tax' ).val(),
    s_country		 = country,
    s_state			 = state,
    s_postcode		 = postcode,
    s_city			 = city,
    s_address		 = address,
    s_address_2		 = address_2,
    has_full_address = true;

   var shipping_methods_item = {};
    shipping_methods_item[ 0 ] = $('input[name^="shipping_method"][type="radio"]:checked').val();

    if ( $( '#ship-to-different-address' ).find( 'input' ).is( ':checked' ) ) {
      s_country		 = $( '#shipping_country' ).val();
      s_state			 = $( '#shipping_state' ).val();
      s_postcode		 = $( ':input#shipping_postcode' ).val();
      s_city			 = $( '#shipping_city' ).val();
      s_address		 = $( ':input#shipping_address_1' ).val();
      s_address_2		 = $( ':input#shipping_address_2' ).val();
    }
    var data = {
      action: 'ajax_checkout_shipping_change_lemon',
      security        : wc_checkout_params.update_order_review_nonce,
      payment_method  : $('input[name="payment_method"]:checked').val(),
      email           : email,
      first_name       : first_name,
      last_name       : last_name,
      phone           : phone,
      country         : country,
      state           : state,
      postcode        : postcode,
      city            : city,
      address         : address,
      address_2       : address_2,
      s_country       : s_country,
      s_state         : s_state,
      s_postcode      : s_postcode,
      s_city          : s_city,
      s_address       : s_address,
      s_address_2     : s_address_2,
      has_full_address: has_full_address,
      company         : company,
      company_wi_id   : company_wi_id,
      company_wi_vat  : company_wi_vat,
      company_wi_tax  : company_wi_tax,
      post_data       : $( 'form.checkout' ).serialize(),
      shipping_method : shipping_methods_item,
      iscompany         : $('input[name="wi_as_company"]').val(),
    };
    //console.log(wc_checkout_params)
    //console.log(data);


    $.ajax({
      type:		'POST',
      url:		ajax_url,
      data:		data,
      success:	function( response ) {
      //console.log(data);
      if (response.success ) {
        $('.shiping-wrap').html(response.shiping_wrap)
        $('.checkout-delivery-sumar-wrap').html(response.deliverysumar_wrap)
        $('.payment-wrap').html(response.payment_wrap_items)
        $('.checkout-total-wrap').html(response.checkout_total)
        $.each(stickyCat,function(index, value){
          stickyCat[index].updateSticky();
        })
      }
      

      paymentonchange();
      schippingonchange();
      companyonchange();
      checkounextbutton(stickyCat);
      checkouprevbutton()
      $( 'body' ).trigger( 'updated_checkout');
      //$('.checkout').off();
      trustpay($('.payment-wrap input[name^="payment_method"]:checked'))
      $('.loading-loader').hide();
      },
      error: function(response) {
        //console.log(data)
        $('.loading-loader').hide();
      }
    });
}

function checkouprevbutton() {

  $('.checkout-steps-prev-button').off('click').on('click',function(e){
    e.preventDefault();
    let step = $(this).attr('data-step');
    $('.stepper-wrap .step-item').removeClass('active');
    $('.stepper-wrap .step-item.step'+step+'').addClass('active');
    $('.checkout-steps-wrap .step').removeClass('active');
    $('.checkout-steps-wrap .step.step'+step+'').addClass('active');
    $('.js-sticky-cartblock').each(function(){
      $(this).css('height', '');
    })
    $.each(stickyCat,function(index, value){
      stickyCat[index].updateSticky();
    })
    $('html, body').animate({scrollTop: 0}, 700);
  })
}

function trustpay (item) {
  if($(item).attr('id') == 'payment_method_trustpay_gateway') {
    $('.payment-wrap input#payment_method_trustpay_gateway_card[name="trustpay_gateway_method"]').prop('checked',true)
  }else if ($(item).attr('id') == 'payment_method_trustpay_gateway_bank') {
    $('.payment-wrap input#payment_method_trustpay_gateway_wire[name="trustpay_gateway_method"]').prop('checked',true)
  }else {
    
    $('.payment-wrap input[name="trustpay_gateway_method"]').each(function(){
      $(this).prop('checked',false)
    })

  }
}