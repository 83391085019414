import $ from 'jquery';

import {pseudoinputtrigger} from './pseudo-input-count';
import {removecaritems} from './remove-cart-item';
import {checkounextbutton} from './checkout-next-button';
import {stickyCat} from './stickyCat';


export function changeajax(quntity,thisitem) {
  
  let dataitemkey = $(thisitem).closest('.order-item').data('link');
  let pack = $(thisitem).closest('.quantity').find('select[name=pack]').val() || 0;
  if(quntity ==0) {
    $(thisitem).closest('.order-item').find('.lemon-delete-cart-item').trigger('click');
  }else {
    $('.loading-loader').show();
    var data = {
      action: 'ajax_change_count_item_lemon',
      itemkey: dataitemkey,
      quantity: quntity,
      pack: pack,

    };
    $.ajax({
      type:		'POST',
      url:		ajax_url,
      data:		data,
      success:	function( response ) {
        //console.log(data);
        if (response.success ) {
          $('.cart-baskets-wrap').html(response.orderitems);
          $('.cart-sumar-wrap').html(response.cartotal);

          pseudoinputtrigger();
          removecaritems();
          cartcouponclick();
          
        } else {
          $('.cart-baskets-wrap').html(response.orderitems);
          $('.cart-sumar-wrap').html(response.cartotal);

          pseudoinputtrigger();
          removecaritems();
          cartcouponclick();
        }
        $('.loading-loader').hide();
      },
      error: function(response) {
        //console.log(data)
        $('.loading-loader').hide();
      }
    });  
  }
  
}


export function cartcouponclick() {
  $('.cart-coupon-wrap .cart-coupon-text').on('click',function(e){
    e.preventDefault();
    $(this).closest('.cart-coupon-wrap').find('.cart-coupon-content').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  $('button[name="apply_coupon"]').on('click',function(e){
    e.preventDefault();
    $('.loading-loader').show();
    $('.coupon .notice').hide()
    $('.coupon .form-control').removeClass('is-invalid');
    let thisval =  $( '#coupon_code' ).val();
    let cart = $(this).closest('.coupon').find('input[name="cart"]').val()
    //console.log(cart)


    var data = {
      action: 'ajax_apply_coupon_lemon',
      security: wc_cart_params_lemon.apply_coupon_nonce,
      coupon_code: thisval,
      cart: cart,
    };
    $.ajax({
      type:		'POST',
      url:		ajax_url,
      data:		data,
      success:	function( response ) {
        //console.log(data);
        if (response.success ) {
          if(response.c == false) {
            $('.coupon .notice').show()
            $('.coupon .form-control').addClass('is-invalid');
          }else {
            $('.cart-baskets-wrap').html(response.orderitems);
            $('.cart-sumar-wrap').html(response.cartotal);
            $('.checkout-total-wrap').html(response.checkout_total)
            pseudoinputtrigger();
            removecaritems();
            cartcouponclick();
            checkounextbutton(stickyCat);
          }
          
        } else {
        }
        $('.loading-loader').hide(); 
      },
      error: function(response) {
        //console.log(data)
        $('.loading-loader').hide();
      }
    });


  })


  $('.remove-coupon').on('click',function(e){
    e.preventDefault();
    $('.loading-loader').show();
    $('.coupon .notice').hide()
    let thisval =  $(this ).attr('data-coupon');
    let cart =  $(this ).attr('data-cart');
    //console.log(thisval)
    let thisbutton = $(this);

    var data = {
      action: 'ajax_remove_coupon_lemon',
      security: wc_cart_params_lemon.remove_coupon_nonce,
      coupon: thisval,
      cart : cart
    };
    $.ajax({
      type:		'POST',
      url:		ajax_url,
      data:		data,
      success:	function( response ) {
        //console.log(data);
        if (response.success ) {
          if(response.c == false) {
            thisbutton.closest('.cart-total-block-content').append('<p class="notice">'+response.m+'</p>')
          }else {
            $('.cart-baskets-wrap').html(response.orderitems);
            $('.cart-sumar-wrap').html(response.cartotal);
            $('.checkout-total-wrap').html(response.checkout_total)
            pseudoinputtrigger();
            removecaritems();
            cartcouponclick();
            checkounextbutton(stickyCat);
          }
          
        } else {
        }
        $('.loading-loader').hide(); 
      },
      error: function(response) {
        //console.log(data)
        $('.loading-loader').hide();
      }
    });
  })
}