import $ from 'jquery';
import Swal from 'sweetalert2';


import {pseudoinputtrigger} from './pseudo-input-count';
import {btnheaderbascet} from './btnheaderbascet';
import {cartcouponclick} from './cart-change';
import {stickyCat, stickyCatCart} from './stickyCat';

import '../../scss/helpers/_modal-remove.scss';


export function removecaritems() {
  $('.lemon-delete-cart-item').on('click',function(e){
    e.preventDefault();
    //console.log('remove')
    let dataitemkey = $(this);
    Swal.fire({
      showClass : {
        popup: 'nomodal-swal swal2-show',
      },
      html:
      '<div class="icon"><i class="material-symbols-outlined">calendar_month</i></div>'+
      '<div class="h4">Naozaj chcete odstrániť túto položku?</div> ',
      showCloseButton: true,
      closeButtonHtml : '<i class="icon-cross"></i>',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText : 'Odobrať z košíka',
      cancelButtonText : 'Zrušiť',
      buttonsStyling : false,
      customClass: {
        confirmButton: 'btn btn-border-gray-200  mb-15',
        denyButton: '',
        cancelButton: 'btn  btn-secondary',
      }
    })
    .then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        removecaritemsfunction(dataitemkey)
      } else if (result.isDenied) {
        return;
      }
    })
  })
}

export function removecaritemsfunction(thisitem) {

  let dataitemkey = $(thisitem).data('link');
  let datacart = $(thisitem).data('cart');
  //console.log(datacart)
  $('.loading-loader').show();
  var data = {
    action: 'ajax_remove_item_lemon',
    itemkey: dataitemkey,
  };
  $.ajax({
    type:		'POST',
    url:		ajax_url,
    data:		data,
    success:	function( response ) {
      if (response.success ) {
        if(response.itemscount == 0 && datacart == true){
          window.location.reload();
          return;
        }else {
          $('.cart-baskets-wrap').html(response.orderitems);
          $('.cartbutton-wrap').html(response.cartbutton);
          $('.cart-sumar-wrap').html(response.cartotal);
          removecaritems();
          pseudoinputtrigger();
          btnheaderbascet();
          cartcouponclick();  
        }
        
        if(datacart == true) {
          $.each(stickyCat,function(index, value){
            stickyCat[index].updateSticky();
          })
          stickyCatCart[1].updateSticky();
        }

      }
      //coupon()
      
    $('.loading-loader').hide();
    },
    error: function(response) {
      //console.log(data)
      $('.loading-loader').hide();
    }
  });
}