import $ from 'jquery';

import {stickyCat} from './stickyCat';
import {changeshipping} from './../checkout';
//console.log(stickyCat)
//var localstickyCat = stickyCat;


export function checkounextbutton(stickyCat) {
  $('.checkout-steps-next-button').off('click').on('click',function(e){
    e.preventDefault();
    let step = $(this).attr('data-step');
    let thisform = $('.checkout');
    let isValid = true;
    $('.form-control').removeClass('is-invalid');

    $('.step2 .form-control, .step2 .form-check-input',$(thisform)).each(function() {
      if ($(this).is(":invalid")) {
        $(this).addClass('is-invalid')
        isValid = false;
      }
    });

    if (!isValid) {
      
      $('html, body').animate({
        scrollTop: $(".form-control:invalid",$(thisform)).offset().top - 150
      }, 300);  
      
      
    }else {
      $('.stepper-wrap .step-item').removeClass('active');
      $('.stepper-wrap .step-item.step'+step+'').addClass('active');
      $('.checkout-steps-wrap .step').removeClass('active');
      $('.checkout-steps-wrap .step.step'+step+'').addClass('active');
      $('.js-sticky-cartblock').each(function(){
        $(this).css('height', '');
      })
      setTimeout(function(){ 
        $.each(stickyCat,function(index, value){
          stickyCat[index].updateSticky();
          //stickyCat[index].stickyPosition(true);
        })  
        /* $('.js-sticky-cartblock').each(function(index){
          stickyCat[index] = new StickySidebar(this, {
            //listen: true,
            topSpacing: 110,
            bottomSpacing: 110,
            containerSelector: '.row',
            resizeSensor: true,
          })
        }) */
      }, 500);
      
      $('html, body').animate({scrollTop: 0}, 700);
      changeshipping()
    }



  })
}